<!-- aside dropdown start-->
<div
    #dropDown
    *ngIf="isMenuOpened()"
    class="aside-dropdown"
    [ngClass]="{ 'aside-dropdown--active': isMenuOpened() }">
    <div class="aside-dropdown__inner">
    <span class="aside-dropdown__close" (click)="closeMenu()">
      <img src="assets/images/template/svg/close.svg" width="15px"/>
    </span>

        <div class="aside-dropdown__item">
            <!-- aside menu start-->
            <ul class="aside-menu">
                <li class="aside-menu__item" *ngFor="let link of menuItems">
                    <a
                            class="aside-menu__link"
                            [routerLink]="link.route"
                            [translate]="'header.navigationMenu.' + link.name"></a>
                </li>
            </ul>
            <ul class="aside-menu" [style.margin-top]="'45px'">
                <li *ngIf="!isLoggedIn" class="aside-menu__item">
                    <a (click)="loginButtonClickHandler()" class="aside-menu__link" translate="header.login"></a>
                </li>
                <li *ngIf="isLoggedIn" class="aside-menu__item">
                    <a (click)="goToDashboard()" class="aside-menu__link" translate="header.dashboard"></a>
                </li>
                <li *ngIf="isLoggedIn" class="aside-menu__item">
                    <a (click)="logout()" class="aside-menu__link" translate="header.logout"></a>
                </li>
            </ul>
            <!-- aside menu end-->
            <div class="aside-inner">
                <span class="aside-inner__title" translate="side-menu.email"></span>
                <a class="aside-inner__link" href="mailto:support@hassaly.com">support&#64;hassaly.com</a>
                <!--        <a class="aside-inner__link" href="mailto:contact-us@hassaly.com">contact-us@hassaly.com</a>-->
            </div>
            <!--      <div class="aside-inner">-->
            <!--        <span class="aside-inner__title" translate="side-menu.phone-numbers"></span>-->
            <!--        <a class="aside-inner__link" href="tel:0521231231">052-1231231</a>-->
            <!--        <a class="aside-inner__link" href="tel:+18009756511">052-1231231</a>-->
            <!--      </div>-->
            <ul class="aside-socials">
                <li *ngFor="let socialLink of socialLinks" class="aside-socials__item">
                    <a class="aside-socials__link" [href]="socialLink.link" target="_blank">
                        <i [class]="'fa fa-' + socialLink.type" aria-hidden="true"></i>
                    </a>
                </li>
            </ul>
        </div>
        <div class="aside-dropdown__item">
            <a class="button button--squared" (click)="openCreateCampaign($event)">
                <span translate="createCampaign"></span>
            </a>
        </div>
    </div>
</div>
<!-- aside dropdown end-->
