import {Component} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {CreateCampaignComponent} from "../create-campaign/create-campaign.component";
import {RouterLink} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {NgForOf} from "@angular/common";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    imports: [
        RouterLink,
        TranslateModule,
        NgForOf
    ],
    standalone: true
})
export class FooterComponent {
    menuItems = [
        {
            name: 'home',
            route: '/',
        },
        {
            name: 'activeCampaigns',
            route: '/campaigns',
        },
        {
            name: 'about',
            route: '/about',
        }
    ];

    socialLinks = [
        {
            type: 'facebook',
            link: 'https://www.facebook.com/hassaly.cf/'
        },
        {
            type: 'instagram',
            link: 'https://www.instagram.com/hassaly.cf/'
        }
    ]

    currentYear = (new Date()).getFullYear();

    constructor(private dialog: MatDialog) {
    }

    getLogoLink() {
        return "assets/images/logos/logo-ext-light.png"
    }

    openCreateCampaignModal() {
        this.dialog.open(CreateCampaignComponent);
    }
}
