<!-- footer start-->
<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-logo">
                    <a class="footer-logo__link" routerLink="/">
                        <img class="footer-logo__img" [src]="getLogoLink()" alt="logo"/>
                    </a>
                </div>
                <ul class="footer-socials">
                    <li *ngFor="let socialLink of socialLinks" class="footer-socials__item">
                        <a class="footer-socials__link" [href]="socialLink.link" target="_blank">
                            <i [class]="'fa fa-' + socialLink.type" aria-hidden="true"></i>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-sm-6 col-lg-3">
                <h4 class="footer__title" translate="footer.contacts"></h4>
                <div class="footer-contacts">
                    <p class="footer-contacts__mail">
                        Email: <a href="mailto:support@hassaly.com">support&#64;hassaly.com</a>
                    </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <h4 class="footer__title" translate=" "></h4>
                <!-- footer nav start-->
                <nav>
                    <ul class="footer-menu">
                        <li *ngFor="let link of menuItems" class="footer-menu__item">
                            <a
                                class="footer-menu__link"
                                [routerLink]="link.route"
                                [translate]="'header.navigationMenu.' + link.name"></a>
                        </li>
                    </ul>
                </nav>
                <!-- footer nav end-->
            </div>
            <div class="col-sm-6 col-lg-3">
                <a class="button footer__button button--filled create-campaign-btn"
                   (click)="openCreateCampaignModal()" translate="createCampaign"></a>
            </div>
        </div>
        <div class="row align-items-baseline">
            <div class="col-md-6">
                <p class="footer-copyright">
                    © {{ currentYear }} Hassaly
                </p>
            </div>
            <div class="col-md-6">
                <div class="footer-privacy">
                    <a class="footer-privacy__link" routerLink="/privacy">{{ 'footer.privacy' | translate }}</a
                    ><span class="footer-privacy__divider">|</span
                ><a class="footer-privacy__link" routerLink="/terms-of-use">{{ 'footer.terms' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer end-->
